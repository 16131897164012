import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {htmlToReact} from '../utils';
import FormField from '../components/FormField';

import Img from 'gatsby-image'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query {
    allFile(filter: {childImageSharp: {id: {ne: null}}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid (maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default class Contact extends React.Component {
    render() {
        const getNode = (path) => this.props.data.allFile.nodes.find(node => node.relativePath.endsWith(path))
        const getFilename = (path) => path.split('/').splice(-1)[0]
        return (
            <Layout {...this.props}>
            <article className="post page post-full">
              <header className="post-header inner-md">
                <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                <div className="post-subtitle">
                  {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                </div>
                )}
              </header>
              {_.get(this.props, 'pageContext.frontmatter.cover', null) && (
              <div className="post-thumbnail">
                <Img fluid={getNode(getFilename(this.props.pageContext.frontmatter.cover)).childImageSharp.fluid} style={{height: 300}}/>
              </div>
              )}
              <div className="post-content inner-md">
                {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                <form name={_.get(this.props, 'pageContext.frontmatter.form_id', null)} id={_.get(this.props, 'pageContext.frontmatter.form_id', null)} {...(_.get(this.props, 'pageContext.frontmatter.form_action', null) ? ({action: _.get(this.props, 'pageContext.frontmatter.form_action', null)}) : null)}method="POST" action="https://api.web3forms.com/submit">
                  <input type="hidden" name="access_key" value="56634daa-0981-4f01-9c7f-114f92939a5b"/>
                  <input type="hidden" name="subject" value="New Submission from Attorney.cy" />
                  <div className="screen-reader-text">
                  {/* eslint-disable jsx-a11y/control-has-associated-label */}
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                  </div>
                  {/* eslint-disable jsx-a11y/control-has-associated-label */}
                  <input type="hidden" name="form-name" value={_.get(this.props, 'pageContext.frontmatter.form_id', null)} />
                  {_.map(_.get(this.props, 'pageContext.frontmatter.form_fields', null), (field, field_idx) => (
                    <FormField key={field_idx} {...this.props} field={field} />
                  ))}
                  <div className="form-submit">
                    <button type="submit" className="button">{_.get(this.props, 'pageContext.frontmatter.submit_label', null)}</button>
                  </div>
                </form>
              </div>
            </article>
            </Layout>
        );
    }
}
